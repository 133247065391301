.not-hovered {
    background-color: transparent;
    box-shadow: none;
}

.hidden-opacity {
    opacity: 0;
}

.no-hiding {
    opacity: 1 !important;
}

.input-margin {
    margin-bottom: 10px !important;
}

.form-control:disabled {
    border: 1px solid #b5b5b5 !important;
}