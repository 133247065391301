@-ms-viewport {
  width: auto !important;
}
/* ---------------------------------------------------------------------- */
/*  Only larger Devices
/* ---------------------------------------------------------------------- */
@media (min-width: 1824px) {
  /* line 8, ../sass/partials/_responsive.scss */
  .layout-boxed > .main-wrapper, .layout-boxed .navbar, .layout-boxed footer, .layout-boxed .newton, .layout-boxed #horizontal-menu {
    max-width: 1470px !important;
  }

  /* line 11, ../sass/partials/_responsive.scss */
  .layout-boxed .main-wrapper > .topbar, .layout-boxed .main-wrapper > footer, .layout-boxed #horizontal-menu {
    left: 50% !important;
    margin-left: -734px  !important;
  }

  /* line 16, ../sass/partials/_responsive.scss */
  .layout-boxed.right-sidebar-open .main-wrapper > .topbar, .layout-boxed.right-sidebar-open .main-wrapper > footer {
    margin-left: -994px !important;
  }
}
@media (min-width: 1224px) and (max-width: 1823px) {
  /* line 23, ../sass/partials/_responsive.scss */
  .layout-boxed > .main-wrapper, .layout-boxed .navbar, .layout-boxed footer, .layout-boxed .newton, .layout-boxed #horizontal-menu {
    max-width: 1170px !important;
  }

  /* line 26, ../sass/partials/_responsive.scss */
  .layout-boxed .main-wrapper > .topbar, .layout-boxed .main-wrapper > footer, .layout-boxed #horizontal-menu {
    left: 50% !important;
    margin-left: -584px !important;
  }

  /* line 31, ../sass/partials/_responsive.scss */
  .layout-boxed.right-sidebar-open .main-wrapper > .topbar, .layout-boxed.right-sidebar-open .main-wrapper > footerfooter {
    margin-left: -844px !important;
  }
}
@media (min-width: 992px) {
  /* 992px */
  /* line 37, ../sass/partials/_responsive.scss */
  .layout-boxed #pageslide-left {
    left: auto !important;
    margin-left: 0 !important;
  }

  /* line 41, ../sass/partials/_responsive.scss */
  .layout-boxed .closedbar {
    left: auto !important;
    display: none;
  }

  /* line 46, ../sass/partials/_responsive.scss */
  .layout-boxed.right-sidebar-open #pageslide-left {
    margin-left: -260px !important;
  }

  /* line 50, ../sass/partials/_responsive.scss */
  .layout-boxed.sidebar-close #pageslide-left {
    display: none;
  }

  /* line 53, ../sass/partials/_responsive.scss */
  .layout-boxed.sidebar-close .main-container {
    margin-left: 0 !important;
  }

  /* line 56, ../sass/partials/_responsive.scss */
  .layout-boxed.sidebar-close .closedbar {
    display: block;
  }

  /* line 59, ../sass/partials/_responsive.scss */
  .layout-boxed.right-sidebar-open .closedbar {
    margin-left: -260px !important;
  }
}
@media (min-width: 1200px) {
  /* 1200px */
  /* line 64, ../sass/partials/_responsive.scss */
  .core-box .title {
    font-size: 16px;
    line-height: 16px;
    font-size: 1.2vw;
    line-height: 1.2vw;
  }
}
/* ---------------------------------------------------------------------- */
/*  Small Devices Only
/* ---------------------------------------------------------------------- */
@media (max-width: 767px) {
  /* 767px */
  /* line 75, ../sass/partials/_responsive.scss */
  .current-user .dropdown-menu {
    right: auto !important;
    left: -100px !important;
  }

  /* line 79, ../sass/partials/_responsive.scss */
  .toolbar .tooltip-notification {
    right: auto;
  }

  /* line 82, ../sass/partials/_responsive.scss */
  .tooltip-notification .tooltip-notification-arrow {
    left: 40%;
  }
}
/* ---------------------------------------------------------------------- */
/*  From Small Devices Up To Medium Devices
/* ---------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 991px) {
  /* 768px - 991px */
  /* line 90, ../sass/partials/_responsive.scss */
  .current-user .dropdown-menu {
    right: auto !important;
    left: -30px !important;
  }
}
/* ---------------------------------------------------------------------- */
/*  Up To Medium Devices
 /* ---------------------------------------------------------------------- */
@media (max-width: 991px) {
  /* 991px */
  /* line 100, ../sass/partials/_responsive.scss */
  .horizontal-menu-fixed .main-container {
    margin-top: 36px !important;
  }

  /* line 103, ../sass/partials/_responsive.scss */
  .main-container {
    margin-left: 0 !important;
  }
  /* line 105, ../sass/partials/_responsive.scss */
  .sidebar-mobile-open .main-container {
    left: 260px !important;
  }

  /* line 109, ../sass/partials/_responsive.scss */
  .main-content .container > .row, #ajax-content > .row {
    padding: 0;
  }

  /* line 113, ../sass/partials/_responsive.scss */
  .sidebar-mobile-open .topbar {
    left: 260px !important;
    right: -260px !important;
  }
  /* line 117, ../sass/partials/_responsive.scss */
  .topbar > .container .navbar-header {
    float: none;
    margin: 12px 0 0 10px;
    width: 140px;
  }

  /* line 123, ../sass/partials/_responsive.scss */
  .topbar {
    min-height: 50px !important;
    position: relative !important;
  }
  /* line 128, ../sass/partials/_responsive.scss */
  .topbar .container .navbar-header .sb-toggle-left {
    height: 50px !important;
    line-height: 50px !important;
  }
  /* line 132, ../sass/partials/_responsive.scss */
  .topbar .container .navbar-header .navbar-brand {
    line-height: 50px !important;
  }
  /* line 136, ../sass/partials/_responsive.scss */
  .topbar .container .topbar-tools {
    height: 50px !important;
    max-height: 50px !important;
  }
  /* line 143, ../sass/partials/_responsive.scss */
  .topbar .container .topbar-tools > ul > li.current-user .dropdown-toggle {
    line-height: 50px !important;
    height: 50px !important;
  }
  /* line 149, ../sass/partials/_responsive.scss */
  .topbar .container .topbar-tools > ul > li.right-menu-toggle a {
    line-height: 40px;
    height: 40px;
  }

  /* line 160, ../sass/partials/_responsive.scss */
  .main-container, .horizontal-menu-fixed .main-container {
    margin-top: 0 !important;
  }

  /* line 163, ../sass/partials/_responsive.scss */
  .topbar > .container .navbar-brand, .logo {
    left: 50px;
  }

  /* line 166, ../sass/partials/_responsive.scss */
  li.dropdown.current-user .dropdown-toggle > i {
    margin-left: 0;
  }

  /* line 169, ../sass/partials/_responsive.scss */
  .dropdown-subview {
    right: auto !important;
    left: -15px !important;
  }
  /* line 172, ../sass/partials/_responsive.scss */
  .dropdown-subview:before {
    right: auto !important;
    left: 49px !important;
  }
  /* line 176, ../sass/partials/_responsive.scss */
  .dropdown-subview:after {
    right: auto !important;
    left: 50px !important;
  }

  /* line 182, ../sass/partials/_responsive.scss */
  .dropdown-messages {
    right: auto !important;
    left: -110px !important;
  }
  /* line 185, ../sass/partials/_responsive.scss */
  .dropdown-messages:before {
    right: auto !important;
    left: 144px !important;
  }
  /* line 189, ../sass/partials/_responsive.scss */
  .dropdown-messages:after {
    right: auto !important;
    left: 145px !important;
  }

  /* line 195, ../sass/partials/_responsive.scss */
  .main-content .row {
    padding: 0;
  }

  /* line 198, ../sass/partials/_responsive.scss */
  #pageslide-left {
    top: 0;
    left: -260px !important;
    margin-left: 0 !important;
    padding-top: 0 !important;
  }
  /* line 203, ../sass/partials/_responsive.scss */
  .sidebar-mobile-open #pageslide-left {
    left: 0 !important;
  }
  /* line 206, ../sass/partials/_responsive.scss */
  .right-sidebar-open #pageslide-left {
    left: -520px !important;
  }
  /* line 209, ../sass/partials/_responsive.scss */
  #pageslide-left .navbar-content {
    top: 0;
  }

  /* line 214, ../sass/partials/_responsive.scss */
  .sidebar-mobile-open #pageslide-right {
    right: -520px;
  }

  /* line 219, ../sass/partials/_responsive.scss */
  .flot-small-container {
    height: 200px;
  }

  /* line 222, ../sass/partials/_responsive.scss */
  .main-content, footer .footer-inner {
    position: relative;
    margin-left: 0 !important;
  }

  /* line 227, ../sass/partials/_responsive.scss */
  .sidebar-mobile-open footer {
    left: 260px !important;
  }

  /* line 231, ../sass/partials/_responsive.scss */
  div.timeline {
    margin: 0;
  }
  /* line 233, ../sass/partials/_responsive.scss */
  div.timeline .columns li {
    float: none !important;
    width: 100% !important;
  }

  /* line 241, ../sass/partials/_responsive.scss */
  .timeline_element {
    margin: 20px auto !important;
  }
  /* line 243, ../sass/partials/_responsive.scss */
  .timeline_element:after {
    display: none;
  }
  /* line 246, ../sass/partials/_responsive.scss */
  .timeline_element:before {
    display: none;
  }

  /* line 250, ../sass/partials/_responsive.scss */
  .timeline-scrubber {
    display: none;
  }

  /* line 253, ../sass/partials/_responsive.scss */
  .faq .nav-tabs {
    float: none;
  }

  /* line 256, ../sass/partials/_responsive.scss */
  .page-header h1 {
    font-size: 24px;
    margin-top: 15px !important;
  }

  /*Lock Screen*/
  /* line 261, ../sass/partials/_responsive.scss */
  .main-ls {
    height: auto;
    left: 0;
    margin: 0 auto !important;
    position: relative;
    top: 0;
    width: 85%;
  }

  /* line 269, ../sass/partials/_responsive.scss */
  .main-ls .logo, .main-ls .copyright {
    text-align: center;
  }

  /* line 272, ../sass/partials/_responsive.scss */
  body.lock-screen .box-ls {
    display: inline-block;
    text-align: center;
    width: 100% !important;
  }

  /* line 277, ../sass/partials/_responsive.scss */
  body.lock-screen .user-info {
    float: none !important;
    width: auto !important;
  }

  /* line 283, ../sass/partials/_responsive.scss */
  .mini-stats li {
    border-right: 1px solid #30ddc6;
  }
  /* line 285, ../sass/partials/_responsive.scss */
  .mini-stats li:last-child {
    border-right: 0 none;
  }

  /* line 290, ../sass/partials/_responsive.scss */
  #sidebar-tab.nav-justified > li {
    display: table-cell;
    width: 1%;
  }
  /* line 293, ../sass/partials/_responsive.scss */
  #sidebar-tab.nav-justified > li a {
    border: 1px solid transparent;
  }
}
