/* ---------------------------------------------------------------------- */
/*  Theme Default
/* ---------------------------------------------------------------------- */
/* line 10, ../../sass/themes/theme-style9.scss */
body {
  background-color: #333333;
}
/* line 12, ../../sass/themes/theme-style9.scss */
body.layout-boxed {
  background: url("../../images/bg.png") #333333;
}

/* line 16, ../../sass/themes/theme-style9.scss */
#slidingbar {
  background: rgba(51, 51, 51, 0.9);
}

/* line 19, ../../sass/themes/theme-style9.scss */
.main-container, #pageslide-left, #pageslide-right {
  background-color: #333333 !important;
}

/* line 22, ../../sass/themes/theme-style9.scss */
.label-default, .badge-default {
  background: #82B541 !important;
}

/* line 26, ../../sass/themes/theme-style9.scss */
.pace .pace-progress {
  background: #232323;
}

/* line 30, ../../sass/themes/theme-style9.scss */
.topbar {
  background: #232323;
  background-color: #3d3d3d 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3d3d3d 0px), to(#232323 100%));
  background-image: -webkit-linear-gradient(top, #3d3d3d 0px, #232323 100%);
  background-image: -moz-linear-gradient(top, #3d3d3d 0px, #232323 100%);
  background-image: -ms-linear-gradient(top, #3d3d3d 0px, #232323 100%);
  background-image: -o-linear-gradient(top, #3d3d3d 0px, #232323 100%);
  background-image: linear-gradient(top, #3d3d3d 0px, #232323 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#3d3d3d 0px', endColorStr='#232323 100%');
}
/* line 33, ../../sass/themes/theme-style9.scss */
.topbar > .container .navbar-brand, .topbar .logo {
  color: #ffffff;
}
/* line 37, ../../sass/themes/theme-style9.scss */
.topbar .topbar-tools > ul > li > a {
  color: #ffffff;
}
/* line 40, ../../sass/themes/theme-style9.scss */
.topbar .topbar-tools > ul > li.right-menu-toggle > a {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #232323;
}
/* line 46, ../../sass/themes/theme-style9.scss */
.topbar .nav li.dropdown.open > .dropdown-toggle, .topbar .nav li.dropdown.active > .dropdown-toggle, .topbar .nav li.dropdown.open.active > .dropdown-toggle {
  background: none;
  color: #ffffff;
}
/* line 50, ../../sass/themes/theme-style9.scss */
.topbar .nav > li:hover > a, .topbar .nav > li:active > a, .topbar .nav > li > a:focus {
  color: #ffffff;
  background: none;
}

/* line 59, ../../sass/themes/theme-style9.scss */
ul.main-navigation-menu > li a {
  border-bottom: none;
  border-top-color: #404040;
  color: white;
}
/* line 63, ../../sass/themes/theme-style9.scss */
ul.main-navigation-menu > li a i {
  color: #737373;
  font-weight: normal;
}
/* line 68, ../../sass/themes/theme-style9.scss */
ul.main-navigation-menu > li.active > a {
  background: #424242 !important;
  border-top: none !important;
  color: #ffffff;
}
/* line 72, ../../sass/themes/theme-style9.scss */
ul.main-navigation-menu > li.active > a i {
  color: #ffffff;
}

/* line 77, ../../sass/themes/theme-style9.scss */
ul.main-navigation-menu li > ul.sub-menu > li > a:hover {
  background: #424242;
  color: #ffffff !important;
}

/* line 81, ../../sass/themes/theme-style9.scss */
ul.main-navigation-menu li > ul.sub-menu > li.open > a, ul.main-navigation-menu li > ul.sub-menu > li.active > a, ul.main-navigation-menu li > ul.sub-menu > li.open > a:hover, ul.main-navigation-menu li > ul.sub-menu > li.active > a:hover {
  background: #262626;
  color: #ffffff !important;
}

/* line 85, ../../sass/themes/theme-style9.scss */
ul.main-navigation-menu > li.open > a, ul.main-navigation-menu > li > a:hover, ul.main-navigation-menu > li:hover > a {
  background-color: #2e2e2e;
}

/* line 88, ../../sass/themes/theme-style9.scss */
ul.main-navigation-menu li.divider {
  background-color: #404040 !important;
}

/* line 91, ../../sass/themes/theme-style9.scss */
ul.main-navigation-menu li.dropdown-header, ul.main-navigation-menu .mega-menu-sub-title {
  color: gray !important;
}

/* line 94, ../../sass/themes/theme-style9.scss */
ul.main-navigation-menu li.dropdown-header, ul.main-navigation-menu .mega-menu-sub-title {
  color: gray !important;
}

/* line 97, ../../sass/themes/theme-style9.scss */
ul.main-navigation-menu .mega-menu .mega-menu-content ul.mega-sub-menu a {
  color: white !important;
}
/* line 99, ../../sass/themes/theme-style9.scss */
ul.main-navigation-menu .mega-menu .mega-menu-content ul.mega-sub-menu a:hover {
  background: none !important;
}

/* line 103, ../../sass/themes/theme-style9.scss */
ul.main-navigation-menu .mega-menu .mega-menu-content ul.mega-sub-menu li.active a, ul.main-navigation-menu .mega-menu .mega-menu-content ul.mega-sub-menu li.active a:hover, ul.main-navigation-menu .mega-menu .mega-menu-content ul.mega-sub-menu li.active a:focus {
  background-color: #262626 !important;
  text-decoration: none;
}

/* line 107, ../../sass/themes/theme-style9.scss */
ul.main-navigation-menu .mega-menu .mega-menu-content ul.mega-sub-menu li a:hover {
  background-color: #424242 !important;
  text-decoration: none;
}

/* line 113, ../../sass/themes/theme-style9.scss */
#horizontal-menu .container {
  background: #333333;
}
/* line 119, ../../sass/themes/theme-style9.scss */
#horizontal-menu .navbar-collapse .navbar-nav > li > a {
  color: #a6a6a6;
}
/* line 121, ../../sass/themes/theme-style9.scss */
#horizontal-menu .navbar-collapse .navbar-nav > li > a:hover {
  color: white;
  background: #262626;
}
/* line 125, ../../sass/themes/theme-style9.scss */
#horizontal-menu .navbar-collapse .navbar-nav > li > a:active, #horizontal-menu .navbar-collapse .navbar-nav > li > a:focus {
  background: #1a1a1a;
}
/* line 130, ../../sass/themes/theme-style9.scss */
#horizontal-menu .navbar-collapse .navbar-nav > li.active > a {
  color: white;
  background: #82B541;
}
/* line 133, ../../sass/themes/theme-style9.scss */
#horizontal-menu .navbar-collapse .navbar-nav > li.active > a:after {
  border-top-color: #82B541;
}

/* line 143, ../../sass/themes/theme-style9.scss */
.main-content {
  background: #DDDDDD;
}

/* line 146, ../../sass/themes/theme-style9.scss */
.go-top {
  color: #ffffff;
}

/* line 149, ../../sass/themes/theme-style9.scss */
.footer-inner {
  color: #ffffff;
}

/* line 153, ../../sass/themes/theme-style9.scss */
#pageslide-left .slide-tools {
  background-color: #262626;
}
/* line 156, ../../sass/themes/theme-style9.scss */
#pageslide-left .btn {
  color: rgba(255, 255, 255, 0.3);
}
/* line 158, ../../sass/themes/theme-style9.scss */
#pageslide-left .btn:hover {
  color: #ffffff;
  background-color: rgba(31, 31, 31, 0.4);
}
/* line 163, ../../sass/themes/theme-style9.scss */
#pageslide-left .user-profile {
  border-color: #404040;
  position: relative;
}
/* line 166, ../../sass/themes/theme-style9.scss */
#pageslide-left .user-profile h5 {
  color: gray;
}
/* line 169, ../../sass/themes/theme-style9.scss */
#pageslide-left .user-profile h4 {
  color: #ffffff;
}

/* line 174, ../../sass/themes/theme-style9.scss */
.closedbar {
  background-color: #262626 !important;
}
/* line 176, ../../sass/themes/theme-style9.scss */
.closedbar:hover {
  background-color: #333333 !important;
}

/* line 181, ../../sass/themes/theme-style9.scss */
#pageslide-right .pageslide-title {
  color: rgba(255, 255, 255, 0.4);
}
/* line 185, ../../sass/themes/theme-style9.scss */
#pageslide-right .notifications a {
  color: rgba(255, 255, 255, 0.7);
  background: #2b2b2b;
}
/* line 188, ../../sass/themes/theme-style9.scss */
#pageslide-right .notifications a:hover {
  background: #262626;
}
/* line 191, ../../sass/themes/theme-style9.scss */
#pageslide-right .notifications a .time {
  color: gray;
}
/* line 197, ../../sass/themes/theme-style9.scss */
#pageslide-right .right-wrapper .nav-tabs li a {
  background: #333333;
  border-bottom-color: #4d4d4d;
}
/* line 201, ../../sass/themes/theme-style9.scss */
#pageslide-right .right-wrapper .nav-tabs li.active a {
  border-right-color: #4d4d4d;
  border-left-color: #4d4d4d;
  color: rgba(255, 255, 255, 0.9);
}
/* line 207, ../../sass/themes/theme-style9.scss */
#pageslide-right .media-list .media {
  border-bottom: 1px solid #4d4d4d;
  margin: 0;
}
/* line 210, ../../sass/themes/theme-style9.scss */
#pageslide-right .media-list .media .media-heading {
  color: #9bb2bf !important;
}
/* line 213, ../../sass/themes/theme-style9.scss */
#pageslide-right .media-list .media a:hover {
  background: #4d4d4d;
}
/* line 216, ../../sass/themes/theme-style9.scss */
#pageslide-right .media-list .media .status-online {
  color: #1FBBA6;
}
/* line 219, ../../sass/themes/theme-style9.scss */
#pageslide-right .media-list .media .media-body span {
  color: #cccccc;
}
/* line 222, ../../sass/themes/theme-style9.scss */
#pageslide-right .media-list .media label {
  color: #cccccc;
}
/* line 226, ../../sass/themes/theme-style9.scss */
#pageslide-right .sidebar-title {
  color: rgba(255, 255, 255, 0.4);
}
/* line 229, ../../sass/themes/theme-style9.scss */
#pageslide-right .sidebar-back {
  color: rgba(255, 255, 255, 0.4);
}
/* line 231, ../../sass/themes/theme-style9.scss */
#pageslide-right .sidebar-back:hover {
  color: rgba(255, 255, 255, 0.6);
}

/* line 237, ../../sass/themes/theme-style9.scss */
#style_selector_container .box-title {
  color: rgba(255, 255, 255, 0.8) !important;
}
/* line 240, ../../sass/themes/theme-style9.scss */
#style_selector_container .images {
  border-bottom: 1px solid #404040;
}
/* line 242, ../../sass/themes/theme-style9.scss */
#style_selector_container .images img.active {
  border: 2px solid #d9d9d9;
}

/* line 247, ../../sass/themes/theme-style9.scss */
footer {
  background-color: #333333;
}
