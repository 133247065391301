/********************
* GOOGLE MAPS STYLE *
*********************/
#map {
    height: 600px;
}

.controls {
    margin-top: 10px;
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 32px;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

#pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 300px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

.pac-container {
    font-family: Roboto;
}

#type-selector {
    color: #fff;
    background-color: #4d90fe;
    padding: 5px 11px 0px 11px;
}

#type-selector label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
}

.topbar .container .topbar-tools {
    margin-top: -5px;
}

.has-maps figure {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

.has-maps figure #map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

#pac-input {
    position: absolute;
    top: 20px;
    z-index: 999;
    left: 40%;
}

/*
  Flaticon icon font: Flaticon
  Creation date: 09/05/2016 10:21
  */

@font-face {
    font-family: "Flaticon";
    src: url("../fonts/file-icons/Flaticon.eot");
    src: url("../fonts/file-icons/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../fonts/file-icons/Flaticon.woff") format("woff"),
    url("../fonts/file-icons/Flaticon.ttf") format("truetype"),
    url("../fonts/file-icons/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../fonts/file-icons/Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: 40px;
    font-style: normal;
}

.flaticon-excel-file:before {
    content: "\f100";
}

.flaticon-pdf-file-format-symbol:before {
    content: "\f101";
}

.flaticon-ppt:before {
    content: "\f102";
}

.flaticon-txt-text-file-extension-symbol:before {
    content: "\f103";
}

.flaticon-word:before {
    content: "\f104";
}

.flaticon-zip-compressed-files-extension:before {
    content: "\f105";
}

/* icon delete button on attached documents */
.file-delete {
    color: #a94442;
    position: absolute;
    right: 25%;
    top: -10%;
    font-size: 20px;
}
/* featured image delete button, needs btn and btn-block*/
.featured-delete {
    color: #ffffff;
    position: absolute;
    top: 30%;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.6);
}

/*datetimepicker z-inex fix*/

.datetimepicker{
    z-index:999 !important;
}
/*margin top fix for main container*/
.main-container{
    margin-top: 43px !important;
}
#pageslide-left{
  padding-top: 43px !important;
}
/* vuje.js loader styles */
.select2-container--default .select2-results__option[aria-disabled=true] {
    display: none;
}
[v-cloak] .v-cloak--block {
    display: block;
}
[v-cloak] .v-cloak--inline {
    display: inline;
}
[v-cloak] .v-cloak--inlineBlock {
    display: inline-block;
}
[v-cloak] .v-cloak--hidden {
    display: none;
}
[v-cloak] .v-cloak--invisible {
    visibility: hidden;
}
.v-cloak--block,
.v-cloak--inline,
.v-cloak--inlineBlock {
    display: none;
}
.content-loader{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 50%;
}
[v-cloak] .v-cloak--block .main-content{
    min-height: 95vh !important;
}

/* HELPERS */

.mt5{
    margin-top: 5px;
}
.mt10{
    margin-top: 10px;
}
.mt15{
    margin-top: 15px;
}
.mt20{
    margin-top: 20px;
}
.mt25{
    margin-top: 25px;
}
.mt30{
    margin-top: 30px;
}

.mb5{
    margin-bottom: 5px;
}
.mb10{
    margin-bottom: 10px;
}
.mb15{
    margin-bottom: 15px;
}
.mb20{
    margin-bottom: 20px;
}
.mb25{
    margin-bottom: 25px;
}
.mb30{
    margin-bottom: 30px;
}

.pv5{
    padding-top: 5px;
    padding-bottom: 5px;
}
.pv10{
    padding-top: 10px;
    padding-bottom: 10px;
}
.pv15{
    padding-top: 15px;
    padding-bottom: 15px;
}
.pv20{
    padding-top: 20px;
    padding-bottom: 20px;
}
.pv25{
    padding-top: 25px;
    padding-bottom: 25px;
}
.pv30{
    padding-top: 30px;
    padding-bottom: 30px;
}


/* MEDIATEKA CHECKBOX */
.mediateka-check{
    width: 30px;
    height: 30px;
    position: absolute;
    color: #8cc657;
    top: -10px;
    right: 0px;
    font-size: 30px !important;
    text-align: center;
    cursor: pointer;
}

/* DATATABLES */
.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-size: 1.2em;
    color: white;
    background-color: lightslategray;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(119,135,153,0)), color-stop(25%, rgba(119,135,153,0.9)), color-stop(75%, rgba(119,135,153,0.9)), color-stop(100%, rgba(119,135,153,0)));
    background: -webkit-linear-gradient(left, rgba(119,135,153,0) 0%, rgba(119,135,153,0.9) 25%, rgba(119,135,153,0.9) 75%, rgba(119,135,153,0) 100%);
    background: -moz-linear-gradient(left, rgba(119,135,153,0) 0%, rgba(119,135,153,0.9) 25%, rgba(119,135,153,0.9) 75%, rgba(119,135,153,0) 100%);
    background: -ms-linear-gradient(left, rgba(119,135,153,0) 0%, rgba(119,135,153,0.9) 25%, rgba(119,135,153,0.9) 75%, rgba(119,135,153,0) 100%);
    background: -o-linear-gradient(left, rgba(119,135,153,0) 0%, rgba(119,135,153,0.9) 25%, rgba(119,135,153,0.9) 75%, rgba(119,135,153,0) 100%);
    background: linear-gradient(to right, rgba(119,135,153,0) 0%, rgba(119,135,153,0.9) 25%, rgba(119,135,153,0.9) 75%, rgba(119,135,153,0) 100%);
}

/* DROPZONE BORDER*/

.dropzone {
    border: 3px dashed;
}
/*fix for bs columns*/
.vertical-align {
    display: flex;
    align-items: center;
}
/*FIX for dd-item on sliders control*/

.dd-handle, .dd3-content{
    height: 34px
}

.modal-loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin:auto;
    z-index: 999;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.select2-results__option[aria-selected=true] {
    display: none;
}

#after-post-loader{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 998;
}

#after-post-loader{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 998;
    background: rgba(255, 255, 255, 0.6);
}

.toolbar .navbar-right > li > a{
    width: 120px !important;
}

.tr-right-cyan{
    border-right: solid 5px cyan;
}
.tr-right-yellow{
    border-right: solid 5px yellow;
}
.tr-right-magenta{
    border-right: solid 5px magenta;
}

ul.main-navigation-menu > li a.just-a-menu-link{
    position: absolute;
    width: 80%;
    height: 3em;
    z-index: 2;
}

.dropzone .dz-preview .dz-details img, .dropzone-previews .dz-preview .dz-details img {
    position: absolute;
    top:0;
    left:0;
    width: 100px;
    height: 100px;
}