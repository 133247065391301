/* ---------------------------------------------------------------------- 
>>> TABLE OF CONTENT
-------------------------------------------------------------------------
1 - OWL CAROUSEL
2 - NDV3 CHART
3 - Easy Pie Chart
4 - Nestable List
5 - Jstree
6 - Form Wizard
7 - jQRangeSlider
8 - Jcrop - Crop Image
9 - Select2
10 - jQuery Tags Input
11 - jQuery Input Limiter
12 - Bootstrap Datepicker
13 - Bootstrap Date Range Picker
14 - Calendar
15 - Inline Editor
16 - Summernote
17 - Multiple File Upload
18 - Perfect Scrollbar
19 - Bootstrap File Upload
/* ---------------------------------------------------------------------- */
/*



*/
/* ---------------------------------------------------------------------- */
/*  OWL CAROUSEL
/* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_owl-carousel.scss */
.panel .owl-item {
  overflow: hidden;
}

/* line 7, ../sass/vendor/_owl-carousel.scss */
.owl-theme .owl-controls .owl-page span, .owl-theme .owl-controls .owl-buttons div {
  background: rgba(255, 255, 255, 0.4) !important;
}

/* line 11, ../sass/vendor/_owl-carousel.scss */
.panel-white .owl-theme .owl-controls .owl-page span, .panel-white .owl-theme .owl-controls .owl-buttons div, .partition-white .owl-theme .owl-controls .owl-page span, .partition-white .owl-theme .owl-controls .owl-buttons div {
  background: rgba(0, 0, 0, 0.2) !important;
}

/* line 14, ../sass/vendor/_owl-carousel.scss */
.panel-note .owl-theme .owl-controls .owl-page span, .panel-note .owl-theme .owl-controls .owl-buttons div, .partition-note .owl-theme .owl-controls .owl-page span, .partition-note .owl-theme .owl-controls .owl-buttons div {
  background: rgba(0, 0, 0, 0.2) !important;
}

/* ---------------------------------------------------------------------- */
/*  NDV3 CHART
/* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_nvd3.scss */
#chart1, #chart4 {
  height: 100%;
  margin: 0;
  min-width: 100px;
  min-height: 100px;
}

/* line 10, ../sass/vendor/_nvd3.scss */
#chart4 .nvd3 .nv-axis line {
  fill: none;
  shape-rendering: crispedges;
  stroke: #1eb29e !important;
}

/* line 15, ../sass/vendor/_nvd3.scss */
#chart4 svg text {
  fill: #ffffff !important;
  font-size: 11px !important;
}

/* line 19, ../sass/vendor/_nvd3.scss */
#chart1 svg text {
  fill: #8B91A0 !important;
  font-size: 11px !important;
}

/* line 23, ../sass/vendor/_nvd3.scss */
.nvtooltip {
  background-color: black !important;
  color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 12px;
  text-align: left;
}

/* line 30, ../sass/vendor/_nvd3.scss */
.nvtooltip h3 {
  font-size: 14px !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  text-align: center;
  border-bottom: 1px solid #333 !important;
}

/* line 37, ../sass/vendor/_nvd3.scss */
#chart3 {
  height: 200px;
  width: 100%;
  margin: 0;
  min-width: 100px;
  min-height: 100px;
}

/* line 44, ../sass/vendor/_nvd3.scss */
#chart3 .nvd3.nv-pie path {
  stroke: #894550 !important;
}

/* line 48, ../sass/vendor/_nvd3.scss */
#chart3 svg text {
  font: 400 12px Arial;
  fill: #ffffff !important;
}

/* ---------------------------------------------------------------------- */
/*  Easy Pie Chart
/* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_easy-pie-chart.scss */
.easy-pie-chart {
  position: relative;
  text-align: center;
}
/* line 7, ../sass/vendor/_easy-pie-chart.scss */
.easy-pie-chart .number {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 70px;
  text-align: center;
}
/* line 14, ../sass/vendor/_easy-pie-chart.scss */
.easy-pie-chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

/* line 20, ../sass/vendor/_easy-pie-chart.scss */
.percent {
  display: inline-block;
  line-height: 70px;
  z-index: 2;
}
/* line 24, ../sass/vendor/_easy-pie-chart.scss */
.percent:after {
  content: '%';
  margin-left: 0.1em;
  font-size: .8em;
}

/* line 30, ../sass/vendor/_easy-pie-chart.scss */
.label-chart {
  color: #333333;
  font-size: 16px;
  font-weight: 300;
  display: inline;
  line-height: 1;
  padding: 0.2em 0.6em 0.3em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

/***
Bootstrap Daterangepicker
***/
/* line 5, ../sass/vendor/_bootstrap-daterangepicker.scss */
.modal-open .daterangepicker {
  z-index: 10055 !important;
}

/* line 9, ../sass/vendor/_bootstrap-daterangepicker.scss */
.daterangepicker td {
  text-shadow: none;
}

/* line 13, ../sass/vendor/_bootstrap-daterangepicker.scss */
.daterangepicker td.active {
  background-color: #4b8df8;
  background-image: none;
  filter: none;
}

/* line 19, ../sass/vendor/_bootstrap-daterangepicker.scss */
.daterangepicker th {
  font-weight: 400;
  font-size: 14px;
}

/* line 24, ../sass/vendor/_bootstrap-daterangepicker.scss */
.daterangepicker .ranges input[type="text"] {
  width: 70px !important;
  font-size: 11px;
  vertical-align: middle;
}

/* line 30, ../sass/vendor/_bootstrap-daterangepicker.scss */
.daterangepicker .ranges label {
  font-weight: 300;
  display: block;
}

/* line 35, ../sass/vendor/_bootstrap-daterangepicker.scss */
.daterangepicker .ranges .btn {
  margin-top: 10px;
}

/* line 39, ../sass/vendor/_bootstrap-daterangepicker.scss */
.daterangepicker.dropdown-menu {
  padding: 5px;
}

/* line 43, ../sass/vendor/_bootstrap-daterangepicker.scss */
.daterangepicker .ranges li {
  color: #333;
}

/* line 47, ../sass/vendor/_bootstrap-daterangepicker.scss */
.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background: #4b8df8 !important;
  border: 1px solid #4b8df8 !important;
  color: #fff;
}

/* line 54, ../sass/vendor/_bootstrap-daterangepicker.scss */
.daterangepicker .range_inputs input {
  margin-bottom: 0 !important;
}

/* ---------------------------------------------------------------------- */
/*  Nestable List
/* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_nestable.scss */
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

/* line 14, ../sass/vendor/_nestable.scss */
.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

/* line 21, ../sass/vendor/_nestable.scss */
.dd-list .dd-list {
  padding-left: 30px;
}

/* line 24, ../sass/vendor/_nestable.scss */
.dd-collapsed .dd-list {
  display: none;
}

/* line 28, ../sass/vendor/_nestable.scss */
.dd-item, .dd-empty, .dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

/* line 38, ../sass/vendor/_nestable.scss */
.dd-handle {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  color: #8B91A0;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #a7acb7;
  background: #ffffff;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

/* line 53, ../sass/vendor/_nestable.scss */
.dd-handle:hover {
  color: #707788;
  background: #fafafa;
}

/* line 58, ../sass/vendor/_nestable.scss */
.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}

/* line 77, ../sass/vendor/_nestable.scss */
.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

/* line 85, ../sass/vendor/_nestable.scss */
.dd-item > button[data-action="collapse"]:before {
  content: '-';
}

/* line 89, ../sass/vendor/_nestable.scss */
.dd-placeholder, .dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

/* line 98, ../sass/vendor/_nestable.scss */
.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #ffffff 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 75%, #ffffff 75%, #ffffff), linear-gradient(45deg, #ffffff 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 75%, #ffffff 75%, #ffffff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

/* line 109, ../sass/vendor/_nestable.scss */
.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}

/* line 114, ../sass/vendor/_nestable.scss */
.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

/* line 117, ../sass/vendor/_nestable.scss */
.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}

/**
 * Nestable Extras
 */
/* line 124, ../sass/vendor/_nestable.scss */
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}

/* line 133, ../sass/vendor/_nestable.scss */
#nestable-menu {
  padding: 0;
  margin: 20px 0;
}

/* line 137, ../sass/vendor/_nestable.scss */
#nestable-output, #nestable2-output {
  width: 100%;
  height: 7em;
  font-size: 13px;
  line-height: 1.333333em;
  padding: 5px;
  margin-bottom: 5px;
}

/* line 145, ../sass/vendor/_nestable.scss */
#nestable2 .dd-handle {
  color: #ffffff;
  border: 1px solid #8B91A0;
  background: #8B91A0;
}

/* line 150, ../sass/vendor/_nestable.scss */
#nestable2 .dd-handle:hover {
  background: #707788;
}

/* line 153, ../sass/vendor/_nestable.scss */
#nestable2 .dd-item > button:before {
  color: #fff;
}

/* line 156, ../sass/vendor/_nestable.scss */
.dd-hover > .dd-handle {
  background: #707788 !important;
}

/**
 * Nestable Draggable Handles
 */
/* line 162, ../sass/vendor/_nestable.scss */
.dd3-content {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px 5px 40px;
  color: #8B91A0;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #a7acb7;
  background: #ffffff;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

/* line 177, ../sass/vendor/_nestable.scss */
.dd3-content:hover {
  color: #707788;
  background: #fafafa;
}

/* line 181, ../sass/vendor/_nestable.scss */
.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}

/* line 184, ../sass/vendor/_nestable.scss */
.dd3-item > button {
  margin-left: 30px;
}

/* line 187, ../sass/vendor/_nestable.scss */
.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 30px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #8B91A0;
  background: #8B91A0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* line 202, ../sass/vendor/_nestable.scss */
.dd3-handle:before {
  content: '\f0c9';
  display: block;
  font-family: FontAwesome;
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #fff;
  font-size: 10px;
  font-weight: normal;
}

/* line 216, ../sass/vendor/_nestable.scss */
.dd3-handle:hover {
  background: #707788;
}

/* ---------------------------------------------------------------------- */
/*  Jstree
/* ---------------------------------------------------------------------- */
/* line 5, ../sass/vendor/_jstree.scss */
.jstree-default .jstree-clicked {
  border: 0;
  background-color: #e1e1e1;
  box-shadow: none;
}

/* line 11, ../sass/vendor/_jstree.scss */
.jstree-default .jstree-hovered {
  border: 0;
  background-color: #eee;
  box-shadow: none;
}

/* line 17, ../sass/vendor/_jstree.scss */
.jstree-default .jstree-wholerow-clicked,
.jstree-wholerow .jstree-wholerow-clicked {
  background: none;
  border: 0;
  background-color: #e1e1e1;
  box-shadow: none;
}

/* line 25, ../sass/vendor/_jstree.scss */
.jstree-default .jstree-wholerow-hovered,
.jstree-wholerow .jstree-wholerow-hovered {
  border: 0;
  background-color: #eee;
  box-shadow: none;
}

/* line 32, ../sass/vendor/_jstree.scss */
.jstree-icon.icon-lg {
  margin-top: 1px;
}

/* line 36, ../sass/vendor/_jstree.scss */
.jstree-open > .jstree-anchor > .fa-folder:before {
  margin-left: 2px;
  content: "\f07c";
}

/* line 41, ../sass/vendor/_jstree.scss */
.jstree-default.jstree-rtl .jstree-last {
  background: transparent;
  background-repeat: no-repeat;
}

/* line 46, ../sass/vendor/_jstree.scss */
.vakata-context,
.vakata-context ul {
  padding: 0;
  min-width: 125px;
  background-color: #ffffff;
  font-size: 14px;
  font-family: "Segoe UI",Helvetica, Arial, sans-serif;
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  border: 1px solid #efefef;
}

/* line 57, ../sass/vendor/_jstree.scss */
.vakata-context li {
  border: 0;
}

/* line 60, ../sass/vendor/_jstree.scss */
.vakata-context li a {
  padding: 0 10px;
  border: 0;
}

/* line 64, ../sass/vendor/_jstree.scss */
.vakata-context li a i {
  display: none;
}

/* line 67, ../sass/vendor/_jstree.scss */
.vakata-context li a .vakata-contextmenu-sep {
  display: none;
}

/* line 71, ../sass/vendor/_jstree.scss */
.vakata-context .vakata-context-hover > a,
.vakata-context li a:hover {
  background-color: #eee;
  color: #333;
  box-shadow: none;
  margin: 0;
}

/* line 78, ../sass/vendor/_jstree.scss */
.vakata-context .vakata-context-hover > a .span,
.vakata-context .vakata-context-hover > a .ins,
.vakata-context li a:hover .span,
.vakata-context li a:hover .ins {
  border: 0 !important;
}

/* line 85, ../sass/vendor/_jstree.scss */
.vakata-context li a span,
.vakata-context li a ins {
  display: none;
  border: 0 !important;
}

/* line 91, ../sass/vendor/_jstree.scss */
.vakata-context .vakata-context-separator a,
.vakata-context-rtl .vakata-context-separator a {
  margin: 0;
  border: 0;
}

/* line 97, ../sass/vendor/_jstree.scss */
.jstree-rename-input {
  background-color: #ffffff !important;
  border: 1px solid #e5e5e5 !important;
  outline: none !important;
  padding: 2px 6px !important;
  margin-right: -4px !important;
}

/* line 104, ../sass/vendor/_jstree.scss */
#jstree-marker {
  z-index: 1049;
}

/* ---------------------------------------------------------------------- */
/*  Form Wizard
 /* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_form-wizard.scss */
.swMain > ul {
  display: table;
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
  position: relative;
  width: 100%;
}

/* line 12, ../sass/vendor/_form-wizard.scss */
.swMain > ul li {
  display: table-cell;
  text-align: center;
  width: 1%;
}

/* line 17, ../sass/vendor/_form-wizard.scss */
.swMain > ul li > a:before {
  border-top: 4px solid #CED1D6;
  content: "";
  display: block;
  font-size: 0;
  height: 1px;
  overflow: hidden;
  position: relative;
  top: 21px;
  width: 100%;
  z-index: 1;
}

/* line 29, ../sass/vendor/_form-wizard.scss */
.swMain > ul li:first-child > a:before {
  left: 50%;
  max-width: 51%;
}

/* line 33, ../sass/vendor/_form-wizard.scss */
.swMain > ul li:last-child > a:before {
  max-width: 50%;
  width: 50%;
}

/* line 37, ../sass/vendor/_form-wizard.scss */
.swMain > ul li > a.selected:before, .swMain li > a.done:before {
  border-color: #5293C4;
}

/* line 40, ../sass/vendor/_form-wizard.scss */
.swMain > ul .stepNumber {
  background-color: #ffffff;
  border: 5px solid #CED1D6;
  border-radius: 100% 100% 100% 100%;
  color: #546474;
  display: inline-block;
  font-size: 15px;
  height: 40px;
  line-height: 30px;
  position: relative;
  text-align: center;
  width: 40px;
  z-index: 2;
}

/* line 54, ../sass/vendor/_form-wizard.scss */
.swMain > ul li > a.selected .stepNumber {
  border-color: #5293C4;
}

/* line 57, ../sass/vendor/_form-wizard.scss */
.swMain ul li > a.done .stepNumber {
  border-color: #5293C4;
  background-color: #5293C4;
  color: #fff;
  text-indent: -9999px;
}

/* line 63, ../sass/vendor/_form-wizard.scss */
.swMain ul li > a.done .stepNumber:before {
  content: "\f00c";
  display: inline;
  float: right;
  font-family: FontAwesome;
  font-weight: 300;
  height: auto;
  text-shadow: none;
  margin-right: 7px;
  text-indent: 0;
}

/* line 74, ../sass/vendor/_form-wizard.scss */
.swMain ul li > a.done.wait .stepNumber {
  background-color: #F6F6F6 !important;
  color: #CCCCCC !important;
  text-indent: -0px !important;
}

/* line 79, ../sass/vendor/_form-wizard.scss */
.swMain ul li > a.done.wait .stepNumber:before {
  content: "" !important;
}

/* line 82, ../sass/vendor/_form-wizard.scss */
.swMain > ul li .stepDesc {
  color: #8B91A0;
  display: block;
  font-size: 14px;
  margin-top: 4px;
  max-width: 100%;
  table-layout: fixed;
  text-align: center;
  word-wrap: break-word;
  z-index: 104;
}

/* line 93, ../sass/vendor/_form-wizard.scss */
.swMain > ul li > a.selected .stepDesc, .swMain li > a.done .stepDesc {
  color: #2B3D53;
}

/* line 96, ../sass/vendor/_form-wizard.scss */
.swMain > ul li > a:hover {
  text-decoration: none;
}

/* line 99, ../sass/vendor/_form-wizard.scss */
.swMain > ul li > a.disabled {
  cursor: default;
}

/* line 102, ../sass/vendor/_form-wizard.scss */
.swMain .progress {
  margin-bottom: 30px;
}

/* line 105, ../sass/vendor/_form-wizard.scss */
.swMain .stepContainer {
  height: auto !important;
}

/* line 108, ../sass/vendor/_form-wizard.scss */
.swMain .loader {
  display: none;
}

/* line 111, ../sass/vendor/_form-wizard.scss */
.swMain [class^="button"], .swMain [class*=" button"] {
  display: none;
}

/* line 114, ../sass/vendor/_form-wizard.scss */
.swMain .close {
  display: none;
}

/* ---------------------------------------------------------------------- */
/*  jQRangeSlider
/* ---------------------------------------------------------------------- */
/* line 5, ../sass/vendor/_jQRangeSlider.scss */
[class^="slider-"] .ui-rangeSlider-bar {
  height: 18px !important;
}

/* line 8, ../sass/vendor/_jQRangeSlider.scss */
[class^="slider-"] .ui-rangeSlider-label {
  padding: 3px 6px !important;
  bottom: 25px !important;
  background: none !important;
}

/* line 13, ../sass/vendor/_jQRangeSlider.scss */
[class^="slider-"] .ui-rangeSlider-label:after {
  content: "\f0d7";
  display: inline;
  float: right;
  font-family: FontAwesome;
  font-size: 16px;
  margin: 0 -6px;
  width: 100%;
  bottom: -14px;
  position: absolute;
  text-align: center;
  text-shadow: none;
}

/* line 26, ../sass/vendor/_jQRangeSlider.scss */
[class^="slider-"] .ui-rangeSlider-arrow {
  height: 14px !important;
  margin: 3px 0 !important;
  font-size: 20px;
  line-height: 14px;
  cursor: pointer;
}

/* line 34, ../sass/vendor/_jQRangeSlider.scss */
[class^="slider-"] .ui-rangeSlider-arrow.ui-rangeSlider-leftArrow {
  background-image: none !important;
}

/* line 37, ../sass/vendor/_jQRangeSlider.scss */
[class^="slider-"] .ui-rangeSlider-arrow.ui-rangeSlider-leftArrow:before {
  content: "\f0d9";
  display: inline;
  float: right;
  font-family: FontAwesome;
  margin-right: 5px;
}

/* line 44, ../sass/vendor/_jQRangeSlider.scss */
[class^="slider-"] .ui-rangeSlider-arrow.ui-rangeSlider-rightArrow {
  background-image: none !important;
}

/* line 47, ../sass/vendor/_jQRangeSlider.scss */
[class^="slider-"] .ui-rangeSlider-arrow.ui-rangeSlider-rightArrow:before {
  content: "\f0da";
  display: inline;
  float: right;
  font-family: FontAwesome;
  margin-right: 5px;
}

/* line 54, ../sass/vendor/_jQRangeSlider.scss */
[class^="slider-"] .ui-rangeSlider-innerBar {
  height: 14px !important;
}

/* line 58, ../sass/vendor/_jQRangeSlider.scss */
[class^="slider-"] .ui-rangeSlider-handle {
  height: 20px !important;
}

/* line 61, ../sass/vendor/_jQRangeSlider.scss */
[class^="slider-"].ui-rangeSlider-disabled {
  opacity: 0.5;
}

/* line 64, ../sass/vendor/_jQRangeSlider.scss */
.slider-teal .ui-rangeSlider-bar {
  background: rgba(86, 144, 153, 0.6) !important;
}

/* line 67, ../sass/vendor/_jQRangeSlider.scss */
.slider-teal .ui-rangeSlider-handle {
  background: rgba(86, 144, 153, 0.8) !important;
}

/* line 70, ../sass/vendor/_jQRangeSlider.scss */
.slider-teal .ui-rangeSlider-label {
  background-image: none !important;
  background-color: #569099 !important;
  color: #FFFFFF !important;
  text-shadow: 0 1px 0 #000000;
}

/* line 76, ../sass/vendor/_jQRangeSlider.scss */
.slider-teal .ui-rangeSlider-label:after {
  color: #569099 !important;
}

/* line 79, ../sass/vendor/_jQRangeSlider.scss */
.slider-teal .ui-rangeSlider-arrow {
  color: #4D8189;
}

/* line 83, ../sass/vendor/_jQRangeSlider.scss */
.slider-bricky .ui-rangeSlider-bar {
  background: rgba(200, 58, 42, 0.6) !important;
}

/* line 86, ../sass/vendor/_jQRangeSlider.scss */
.slider-bricky .ui-rangeSlider-handle {
  background: rgba(200, 58, 42, 0.8) !important;
}

/* line 89, ../sass/vendor/_jQRangeSlider.scss */
.slider-bricky .ui-rangeSlider-label {
  background-image: none !important;
  background-color: #C83A2A !important;
  color: #FFFFFF !important;
  text-shadow: 0 1px 0 #000000;
}

/* line 95, ../sass/vendor/_jQRangeSlider.scss */
.slider-bricky .ui-rangeSlider-label:after {
  color: #C83A2A !important;
}

/* line 98, ../sass/vendor/_jQRangeSlider.scss */
.slider-bricky .ui-rangeSlider-arrow {
  color: #B33426;
}

/* line 102, ../sass/vendor/_jQRangeSlider.scss */
.slider-purple .ui-rangeSlider-bar {
  background: rgba(87, 81, 123, 0.6) !important;
}

/* line 105, ../sass/vendor/_jQRangeSlider.scss */
.slider-purple .ui-rangeSlider-handle {
  background: rgba(87, 81, 123, 0.8) !important;
}

/* line 108, ../sass/vendor/_jQRangeSlider.scss */
.slider-purple .ui-rangeSlider-label {
  background-image: none !important;
  background-color: #57517B !important;
  color: #FFFFFF !important;
  text-shadow: 0 1px 0 #000000;
}

/* line 114, ../sass/vendor/_jQRangeSlider.scss */
.slider-purple .ui-rangeSlider-label:after {
  color: #57517B !important;
}

/* line 117, ../sass/vendor/_jQRangeSlider.scss */
.slider-purple .ui-rangeSlider-arrow {
  color: #413D5C;
}

/* line 121, ../sass/vendor/_jQRangeSlider.scss */
.slider-green .ui-rangeSlider-bar {
  background: rgba(61, 148, 0, 0.6) !important;
}

/* line 124, ../sass/vendor/_jQRangeSlider.scss */
.slider-green .ui-rangeSlider-handle {
  background: rgba(61, 148, 0, 0.8) !important;
}

/* line 127, ../sass/vendor/_jQRangeSlider.scss */
.slider-green .ui-rangeSlider-label {
  background-image: none !important;
  background-color: #3D9400 !important;
  color: #FFFFFF !important;
  text-shadow: 0 1px 0 #000000;
}

/* line 133, ../sass/vendor/_jQRangeSlider.scss */
.slider-green .ui-rangeSlider-label:after {
  color: #3D9400 !important;
}

/* line 136, ../sass/vendor/_jQRangeSlider.scss */
.slider-green .ui-rangeSlider-arrow {
  color: #327B00;
}

/* line 140, ../sass/vendor/_jQRangeSlider.scss */
.slider-yellow .ui-rangeSlider-bar {
  background: rgba(255, 167, 34, 0.6) !important;
}

/* line 143, ../sass/vendor/_jQRangeSlider.scss */
.slider-yellow .ui-rangeSlider-handle {
  background: rgba(255, 167, 34, 0.8) !important;
}

/* line 146, ../sass/vendor/_jQRangeSlider.scss */
.slider-yellow .ui-rangeSlider-label {
  background-image: none !important;
  background-color: #FFA722 !important;
  color: #FFFFFF !important;
  text-shadow: 0 1px 0 #482C00;
}

/* line 152, ../sass/vendor/_jQRangeSlider.scss */
.slider-yellow .ui-rangeSlider-label:after {
  color: #FFB848 !important;
}

/* line 155, ../sass/vendor/_jQRangeSlider.scss */
.slider-yellow .ui-rangeSlider-arrow {
  color: #FFAE2F;
}

/* line 159, ../sass/vendor/_jQRangeSlider.scss */
.slider-blue .ui-rangeSlider-bar {
  background: rgba(54, 79, 106, 0.6) !important;
}

/* line 162, ../sass/vendor/_jQRangeSlider.scss */
.slider-blue .ui-rangeSlider-handle {
  background: rgba(54, 79, 106, 0.8) !important;
}

/* line 165, ../sass/vendor/_jQRangeSlider.scss */
.slider-blue .ui-rangeSlider-label {
  background-image: none !important;
  background-color: #364F6A !important;
  color: #FFFFFF !important;
  text-shadow: 0 1px 0 #000000;
}

/* line 171, ../sass/vendor/_jQRangeSlider.scss */
.slider-blue .ui-rangeSlider-label:after {
  color: #364F6A !important;
}

/* line 174, ../sass/vendor/_jQRangeSlider.scss */
.slider-blue .ui-rangeSlider-arrow {
  color: #2D4259;
}

/* ---------------------------------------------------------------------- */
/*  Jcrop - Crop Image
/* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_jcrop.scss */
.crop-image form {
  margin: 1.5em 0;
}

/* line 7, ../sass/vendor/_jcrop.scss */
.jcrop-holder #preview-pane {
  display: block;
  position: absolute;
  z-index: 1000;
  top: 10px;
  right: -280px;
  padding: 6px;
  border: 1px rgba(0, 0, 0, 0.4) solid;
  background-color: white;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
}

/* The Javascript code will set the aspect ratio of the crop
 area based on the size of the thumbnail preview,
 specified here */
/* line 26, ../sass/vendor/_jcrop.scss */
#preview-pane .preview-container {
  width: 250px;
  height: 170px;
  overflow: hidden;
}


/* ---------------------------------------------------------------------- */
/*  jQuery Tags Input
/* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_tags-input.scss */
div.tagsinput span.tag {
  background: #e8edf1;
  border-radius: 8px;
  color: #555555;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  padding: 8px;
  font-size: 14px;
  line-height: 14px;
  border: none;
}

/* line 16, ../sass/vendor/_tags-input.scss */
div.tagsinput span.tag a {
  color: #555555;
  font-size: 14px;
  font-weight: bold;
  color: transparent;
  position: relative;
}

/* line 24, ../sass/vendor/_tags-input.scss */
div.tagsinput span.tag a:before {
  color: #94a1b2;
  content: "\f00d";
  font-family: 'FontAwesome';
  font-size: 12px;
  position: absolute;
  left: 0;
  right: 0;
}

/* line 33, ../sass/vendor/_tags-input.scss */
div.tagsinput input {
  font-size: 14px;
  width: 90px;
}

/* line 37, ../sass/vendor/_tags-input.scss */
.not_valid {
  border-radius: 8px;
}

/* ---------------------------------------------------------------------- */
/* jQuery Input Limiter
/* ---------------------------------------------------------------------- */
/* line 5, ../sass/vendor/_inputlimiter.scss */
.limiterBox {
  border-top: 0;
  background-color: #65BCDA !important;
  padding: 3px 6px;
  font-size: 12px;
  color: #FFF;
  margin-top: 6px;
}

/* line 13, ../sass/vendor/_inputlimiter.scss */
.limiterBox:after {
  display: none;
}

/* line 16, ../sass/vendor/_inputlimiter.scss */
.limiterBox:before {
  display: block;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -8px;
  left: 50%;
  margin-left: -5px;
  border-color: transparent;
  border-style: solid;
  border-bottom-color: #65BCDA;
  border-width: 0 8px 8px;
}

/* ---------------------------------------------------------------------- */
/* Bootstrap Datepicker
/* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_date-picker.scss */
.datepicker {
  border-radius: 2px;
  font-size: 12px;
  padding: 10px 16px;
}

/* line 9, ../sass/vendor/_date-picker.scss */
.datepicker thead tr .datepicker-switch {
  color: #6f7b8a;
  font-size: 13px;
}

/* line 13, ../sass/vendor/_date-picker.scss */
.datepicker thead tr .prev {
  color: #0090d9;
  content: "";
  font-size: 0;
}

/* line 18, ../sass/vendor/_date-picker.scss */
.datepicker thead tr .prev:before {
  color: #0090d9;
  content: "\f053";
  font-family: 'FontAwesome';
  font-size: 13px;
}

/* line 24, ../sass/vendor/_date-picker.scss */
.datepicker thead tr .next {
  color: #0090d9;
  content: "";
  font-size: 0;
}

/* line 29, ../sass/vendor/_date-picker.scss */
.datepicker thead tr .next:before {
  color: #0090d9;
  content: "\f054";
  font-family: 'FontAwesome';
  font-size: 13px;
}

/* line 35, ../sass/vendor/_date-picker.scss */
.datepicker thead tr .dow {
  color: #0090d9;
  font-size: 11px;
  text-transform: uppercase;
}

/* line 40, ../sass/vendor/_date-picker.scss */
.datepicker tbody tr .odd {
  color: #d0d3d8;
}

/* line 43, ../sass/vendor/_date-picker.scss */
.datepicker table tr td.day:hover {
  background: none repeat scroll 0 0 #eeeeee;
  opacity: 0.65;
}

/* line 47, ../sass/vendor/_date-picker.scss */
.datepicker table tr td span {
  border-radius: 4px;
}

/* line 50, ../sass/vendor/_date-picker.scss */
.datepicker th, .datepicker td {
  padding: 5.5px !important;
}

/* line 53, ../sass/vendor/_date-picker.scss */
.datepicker table tr td.old, .datepicker table tr td.new {
  color: #d0d3d8;
}

/* line 56, ../sass/vendor/_date-picker.scss */
.datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover {
  background-image: none;
  font-weight: 600;
  text-shadow: none;
}

/* line 61, ../sass/vendor/_date-picker.scss */
.datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
  background-color: #e5e9ec;
  background-image: none;
  color: #ffffff;
}

/* line 66, ../sass/vendor/_date-picker.scss */
.datepicker table tr td.active:hover, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active:active, .datepicker table tr td.active:hover:active, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.active, .datepicker table tr td.active.active:hover, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.active:hover, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled.disabled:hover, .datepicker table tr td.active[disabled], .datepicker table tr td.active[disabled]:hover, .datepicker table tr td.active.disabled[disabled], .datepicker table tr td.active.disabled[disabled]:hover {
  background-color: #0090d9;
}

/* line 69, ../sass/vendor/_date-picker.scss */
.datepicker table tr td span.active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover {
  background-image: none;
  border: medium none;
  text-shadow: none;
}

/* line 74, ../sass/vendor/_date-picker.scss */
.datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover:hover, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.active, .datepicker table tr td span.active.active:hover, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled.active:hover, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled.disabled, .datepicker table tr td span.active.disabled.disabled:hover, .datepicker table tr td span.active[disabled], .datepicker table tr td span.active[disabled]:hover, .datepicker table tr td span.active.disabled[disabled], .datepicker table tr td span.active.disabled[disabled]:hover {
  background-color: #0090d9;
}

/* ---------------------------------------------------------------------- */
/* Bootstrap Datepicker
/* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_time-picker.scss */
.bootstrap-timepicker-widget table td a i {
  color: #0090d9;
}

/* ---------------------------------------------------------------------- */
/* Bootstrap Date Range Picker
/* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_date-range-picker.scss */
.daterangepicker .calendar th {
  color: #0090d9;
}

/* ---------------------------------------------------------------------- */
/*  Calendar
/* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_calendar.scss */
#calendar {
  position: relative;
}

/* line 7, ../sass/vendor/_calendar.scss */
.fc-event, #event-categories .event-category {
  padding: 2px 5px;
  background: #edeef0 !important;
  border: 1px solid #e8e9ec !important;
  color: #8B91A0 !important;
}

/* line 13, ../sass/vendor/_calendar.scss */
#event-categories .event-category {
  cursor: move;
}

/* line 16, ../sass/vendor/_calendar.scss */
.fc-day-number {
  margin: 2px 2px 0 0;
}

/* line 19, ../sass/vendor/_calendar.scss */
.fc-header-title h2 {
  color: #8B91A0;
  font-weight: 200;
  letter-spacing: -1px;
}

/* line 24, ../sass/vendor/_calendar.scss */
.fc-today .fc-day-number {
  font-weight: bold;
}

/* line 28, ../sass/vendor/_calendar.scss */
.fc-state-highlight {
  background: #F5F5F5 !important;
}

/* line 31, ../sass/vendor/_calendar.scss */
.fc-button {
  color: #858585 !important;
  height: 40px !important;
  line-height: 40px;
  opacity: 0.6;
}

/* line 37, ../sass/vendor/_calendar.scss */
.fc-button:hover {
  opacity: 1;
}

/* line 40, ../sass/vendor/_calendar.scss */
.fc-button-prev, .fc-button-next {
  padding: 0;
  width: 40px;
  line-height: 40px;
  text-align: center;
  position: relative;
  text-indent: -9999px;
}

/* line 48, ../sass/vendor/_calendar.scss */
.fc-button-prev .fc-text-arrow, .fc-button-next .fc-text-arrow {
  font-weight: bold;
}

/* line 51, ../sass/vendor/_calendar.scss */
.fc-button-prev:after, .fc-button-next:after {
  font-family: FontAwesome;
  text-indent: 0;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

/* line 60, ../sass/vendor/_calendar.scss */
.fc-button-prev:after {
  content: "\f104";
}

/* line 63, ../sass/vendor/_calendar.scss */
.fc-button-next:after {
  content: "\f105";
}

/* line 66, ../sass/vendor/_calendar.scss */
.fc-state-default {
  background-color: #ffffff !important;
  background-image: none !important;
  border-color: #DDDDDD !important;
  box-shadow: none !important;
  color: #666666;
  text-shadow: none !important;
  border-radius: 0 !important;
}

/* line 75, ../sass/vendor/_calendar.scss */
.fc-state-down, .fc-state-active {
  opacity: 1;
}

/* line 78, ../sass/vendor/_calendar.scss */
.fc-header-left {
  position: absolute !important;
  top: 0;
  left: 15px;
  width: 50% !important;
}

/* line 84, ../sass/vendor/_calendar.scss */
.fc-header-right {
  position: absolute !important;
  top: 0;
  right: 15px;
  width: 50% !important;
}

/* line 90, ../sass/vendor/_calendar.scss */
.fc-header-center {
  display: block !important;
  padding-top: 60px !important;
}

/* line 94, ../sass/vendor/_calendar.scss */
.event-category {
  color: #ffffff;
  padding: 5px;
  border-radius: 3px;
  margin: 5px 0;
}

/* line 101, ../sass/vendor/_calendar.scss */
.fc-border-separate tr.fc-last th {
  border-top: none !important;
  border-left: none !important;
}

/* line 105, ../sass/vendor/_calendar.scss */
.fc-border-separate th.fc-last {
  border-right: none !important;
}

/* line 108, ../sass/vendor/_calendar.scss */
.fc-grid th {
  text-align: right;
}

/* line 111, ../sass/vendor/_calendar.scss */
.fc-event {
  background-color: #F0F0F0;
  border: none;
  color: #333333;
  font-size: 12px;
}

/* line 117, ../sass/vendor/_calendar.scss */
.fc-event .fc-content > span:first-child:before, .event-category:before {
  content: "\f111";
  font-family: FontAwesome;
  font-size: 6px;
  line-height: 16px;
  padding-right: 5px;
  color: #00AEFF;
  float: left;
}

/* line 126, ../sass/vendor/_calendar.scss */
.event-category {
  color: #858585;
}

/* line 129, ../sass/vendor/_calendar.scss */
.event-category:before {
  line-height: 20px;
}

/* line 132, ../sass/vendor/_calendar.scss */
.event-cancelled .fc-content > span:first-child:before, .event-category.event-cancelled:before {
  color: #E6C800;
}

/* line 135, ../sass/vendor/_calendar.scss */
.event-home .fc-content > span:first-child:before, .event-category.event-home:before {
  color: #FF2968;
}

/* line 138, ../sass/vendor/_calendar.scss */
.event-overtime .fc-content > span:first-child:before, .event-category.event-overtime:before {
  color: #711A76;
}

/* line 141, ../sass/vendor/_calendar.scss */
.event-generic .fc-content > span:first-child:before, .event-category.event-generic:before {
  color: #00AEFF;
}

/* line 144, ../sass/vendor/_calendar.scss */
.event-job .fc-content > span:first-child:before, .event-category.event-job:before {
  color: #882F00;
}

/* line 147, ../sass/vendor/_calendar.scss */
.event-offsite .fc-content > span:first-child:before, .event-category.event-offsite:before {
  color: #44A703;
}

/* line 150, ../sass/vendor/_calendar.scss */
.event-todo .fc-content > span:first-child:before, .event-category.event-todo:before {
  color: #FF3B30;
}

/* line 153, ../sass/vendor/_calendar.scss */
.fc-view-month .fc-event-time {
  display: none;
}

/* line 156, ../sass/vendor/_calendar.scss */
.fc-icon-fa {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.33333333em;
}

/* ---------------------------------------------------------------------- */
/*  Inline Editor
/* ---------------------------------------------------------------------- */
/* line 5, ../sass/vendor/_ckeditor.scss */
#inline-container [contenteditable="true"] {
  padding: 10px;
}

/* line 8, ../sass/vendor/_ckeditor.scss */
#inline-container {
  border: 1px solid #CCCCCC;
  margin: 30px auto 0;
  padding: 10px;
}

/* line 13, ../sass/vendor/_ckeditor.scss */
#inline-container hr {
  background-color: #05B2D2;
  height: 5px;
  color: #05B2D2;
  border: 0;
}

/* line 19, ../sass/vendor/_ckeditor.scss */
#inline-header {
  overflow: hidden;
  padding: 0 0 30px;
  position: relative;
}

/* line 24, ../sass/vendor/_ckeditor.scss */
#inline-headerLeft {
  padding-top: 10px;
}

/* line 28, ../sass/vendor/_ckeditor.scss */
#inline-headerLeft h2, #inline-headerLeft h3 {
  font-weight: normal;
  margin: 0;
  overflow: hidden;
  text-align: center;
}

/* line 34, ../sass/vendor/_ckeditor.scss */
#inline-headerLeft h2 {
  font-family: "Arial Black", arial-black;
  font-size: 52px;
  line-height: 1.1em;
  text-transform: uppercase;
}

/* line 40, ../sass/vendor/_ckeditor.scss */
#inline-headerLeft h3 {
  color: #666666;
  font-size: 24px;
  line-height: 1.1em;
  margin: 0.2em 0 0;
}

/* line 46, ../sass/vendor/_ckeditor.scss */
#inline-headerRight p {
  margin: 0;
  text-align: justify;
}

/* line 50, ../sass/vendor/_ckeditor.scss */
#inline-headerRight p + p {
  margin-top: 20px;
}

/* line 53, ../sass/vendor/_ckeditor.scss */
#inline-headerRight div {
  color: #666666;
  font-size: 16px;
}

/* line 57, ../sass/vendor/_ckeditor.scss */
#inline-columns {
  color: #333333;
  overflow: hidden;
  padding-top: 20px;
}

/* line 62, ../sass/vendor/_ckeditor.scss */
#inline-columns blockquote {
  font-family: "Times New Roman", Times, Georgia, serif;
  font-size: 2em;
  font-style: italic;
  margin: 30px 40px;
  position: relative;
  border: none;
}

/* line 70, ../sass/vendor/_ckeditor.scss */
#inline-columns blockquote:before {
  color: #666666;
  content: "\f10d";
  font-size: 45px;
  left: -40px;
  line-height: 60px;
  position: absolute;
  text-align: center;
  width: 35px;
  clear: both;
  display: block;
  font-family: FontAwesome;
  font-weight: bold;
  position: absolute;
  text-align: center;
}

/* line 86, ../sass/vendor/_ckeditor.scss */
#inline-tagLine {
  border-top: 5px solid #05B2D2;
  padding-top: 20px;
}

/* line 90, ../sass/vendor/_ckeditor.scss */
#inline-taglist {
  display: inline-block;
  font-weight: bold;
  margin: 0 0 0 20px;
}

/* ---------------------------------------------------------------------- */
/*  Summernote
/* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_summernote.scss */
.noteWrap {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}

/* line 9, ../sass/vendor/_summernote.scss */
.noteWrap .note-editor {
  border: none;
}

/* line 12, ../sass/vendor/_summernote.scss */
.noteWrap .note-editor .note-toolbar {
  background: none;
  border-bottom: none;
  padding: 0;
}

/* line 17, ../sass/vendor/_summernote.scss */
.noteWrap .note-editor .note-editable {
  min-height: 240px;
  background: url("../images/line_detail.png");
  line-height: 20px;
  padding: 0;
  margin-top: 20px;
  color: #858585;
}

/* line 25, ../sass/vendor/_summernote.scss */
.noteWrap .note-editor .note-statusbar {
  background: none;
}

/* line 28, ../sass/vendor/_summernote.scss */
.noteWrap .note-editor .note-statusbar .note-resizebar {
  border-top: none;
}

/* line 1, ../sass/vendor/_bootstrap-sliders.scss */
.slider {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

/* line 6, ../sass/vendor/_bootstrap-sliders.scss */
.slider.slider-horizontal {
  margin: 10px 0;
  width: 210px !important;
}

/* line 10, ../sass/vendor/_bootstrap-sliders.scss */
.slider.slider-horizontal .slider-track {
  height: 5px !important;
  left: 0;
  margin-top: -5px;
  top: 50%;
  width: 100%;
}

/* line 17, ../sass/vendor/_bootstrap-sliders.scss */
.slider.slider-horizontal .slider-handle {
  margin-top: -10px !important;
}

/* line 20, ../sass/vendor/_bootstrap-sliders.scss */
.slider.slider-vertical {
  margin: 0 10px;
}

/* line 23, ../sass/vendor/_bootstrap-sliders.scss */
.slider.slider-vertical .slider-handle {
  margin-left: -10px !important;
}

/* line 26, ../sass/vendor/_bootstrap-sliders.scss */
.slider.slider-vertical .slider-track {
  width: 5px !important;
}

/* line 29, ../sass/vendor/_bootstrap-sliders.scss */
.slider.slider-vertical .slider-handle {
  margin-left: -10px !important;
}

/* line 32, ../sass/vendor/_bootstrap-sliders.scss */
.slider-handle.round {
  background: linear-gradient(to bottom, #dddddd 13%, #ffffff 97%) repeat scroll 0 0 transparent;
  box-shadow: 0 2px 1px -1px #ffffff inset, 0 1px 3px rgba(0, 0, 0, 0.39);
  height: 26px;
  opacity: 1;
  width: 26px;
}

/* line 39, ../sass/vendor/_bootstrap-sliders.scss */
.slider-selection {
  background-color: #dddddd !important;
  background-image: none !important;
  border-radius: none;
  box-shadow: none;
}

/* line 45, ../sass/vendor/_bootstrap-sliders.scss */
.slider.slider-blue .slider-track {
  background: #5F8295;
}

/* line 48, ../sass/vendor/_bootstrap-sliders.scss */
.slider.slider-green .slider-track {
  background: #1FBBA6;
}

/* line 51, ../sass/vendor/_bootstrap-sliders.scss */
.slider.slider-orange .slider-track {
  background: #F58A5C;
}

/* line 54, ../sass/vendor/_bootstrap-sliders.scss */
.slider.slider-red .slider-track {
  background: #e66b6b;
}

/* line 57, ../sass/vendor/_bootstrap-sliders.scss */
.slider.slider-yellow .slider-track {
  background: #FFB848;
}

/* line 60, ../sass/vendor/_bootstrap-sliders.scss */
.slider.slider-purple .slider-track {
  background: #804C75;
}

/* line 63, ../sass/vendor/_bootstrap-sliders.scss */
.slider.slider-azure .slider-track {
  background: #00BDCC;
}

/* ---------------------------------------------------------------------- */
/*  Multiple File Upload
/* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_multiple-upload.scss */
.fileupload .uneditable-input {
  white-space: nowrap;
}

/* line 7, ../sass/vendor/_multiple-upload.scss */
.fileupload-new .input-group .btn-file {
  -webkit-border-radius: 0 3px 3px 0 !important;
  -moz-border-radius: 0 3px 3px 0 !important;
  border-radius: 0 3px 3px 0 !important;
}

/* line 12, ../sass/vendor/_multiple-upload.scss */
.fileinput-button {
  overflow: hidden;
  position: relative;
}

/* line 16, ../sass/vendor/_multiple-upload.scss */
.fileinput-button input {
  cursor: pointer;
  direction: ltr;
  font-size: 23px;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-300px, 0px) scale(4);
}

/* ---------------------------------------------------------------------- */
/*  Perfect Scrollbar
/* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_perfect-scrollbar.scss */
.ps-container .ps-scrollbar-x-rail:hover,
.ps-container .ps-scrollbar-x-rail.hover {
  background-color: #eee !important;
}

/* line 8, ../sass/vendor/_perfect-scrollbar.scss */
.ps-container .ps-scrollbar-y-rail {
  width: 6px;
  -moz-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  -webkit-transition: width 0.2s linear;
  transition: width 0.2s linear;
}

/* line 13, ../sass/vendor/_perfect-scrollbar.scss */
.ps-container .ps-scrollbar-y {
  width: 6px;
  -moz-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  -webkit-transition: width 0.2s linear;
  transition: width 0.2s linear;
}

/* line 17, ../sass/vendor/_perfect-scrollbar.scss */
.ps-container:hover .ps-scrollbar-y-rail,
.ps-container.hover .ps-scrollbar-y-rail {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4;
}

/* line 21, ../sass/vendor/_perfect-scrollbar.scss */
.ps-container .ps-scrollbar-y-rail:hover,
.ps-container .ps-scrollbar-y-rail.hover,
.ps-container .ps-scrollbar-y-rail.in-scrolling {
  background-color: #eee;
  width: 10px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  opacity: 0.6;
}

/* line 28, ../sass/vendor/_perfect-scrollbar.scss */
.ps-container .ps-scrollbar-y-rail:hover .ps-scrollbar-y,
.ps-container .ps-scrollbar-y-rail.hover .ps-scrollbar-y,
.ps-container .ps-scrollbar-y-rail.in-scrolling .ps-scrollbar-y {
  background-color: #999;
  width: 10px;
}

/* ---------------------------------------------------------------------- */
/*  Bootstrap File Upload
/* ---------------------------------------------------------------------- */
/* line 4, ../sass/vendor/_file-upload.scss */
.fileupload-new.thumbnail {
  width: 200px;
  height: 150px;
}

/* line 8, ../sass/vendor/_file-upload.scss */
.fileupload-preview.thumbnail {
  max-width: 200px;
  max-height: 150px;
  line-height: 20px;
}
